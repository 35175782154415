import React from "react"
import styled from "styled-components"

const GridTarifs = () => {
  return (
    <TarifsWrapper>
      <hr /> <h2>Dépannage à domicile</h2> <hr />
      <h5>
        Installations, problème matériel ou logiciel, diagnostic, réparation,
        changement de pièce.
      </h5>
      <ul>
        <li>
          <div className="Price">
            <span className="elementTitle">Intervention de 1h</span>
            <hr />
            <span className="elementPrice">60 €</span>
          </div>
        </li>
        <li>Intervention de 1h15</li>
        <li>Intervention de 1h30</li>
        <li>Puis toutes les 15 min</li>
      </ul>
    </TarifsWrapper>
  )
}

const TarifsWrapper = styled.div`
  width: 18rem;

  .Price {
    display: flex;
    li {
      .elementSeparator {
        margin: 0 15%;
        width: 100%;
        border-top: 3px solid black;
      }
    }
  }
`

export default GridTarifs
