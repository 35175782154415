import React from "react"
import GridTarifs from "../components/GridTarifs"
import Layout from "../components/layout"
import styled from "styled-components"

const Tarifs = () => {
  return (
    <Layout>
      <TarifsWrapper>
        <GridTarifsWrapper>
          <GridTarifs />
          <GridTarifs />
        </GridTarifsWrapper>
      </TarifsWrapper>
    </Layout>
  )
}

const TarifsWrapper = styled.div`
  width: 90%;
  margin: 2% auto;

  h3 {
    margin: 4% 0 0 0;
    text-align: center;
  }
  p {
    margin: 5% 0;
  }
`

const GridTarifsWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 3%;
  justify-content: space-around;
`

export default Tarifs
